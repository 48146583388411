<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-row v-if="purchaseQuoteData == undefined || formShow == true">
      <b-col cols="12">
        <center class="mt-5">Loading...</center>
      </b-col>
    </b-row>

    <b-row v-else class="invoice-preview">
      <b-col cols="12" xl="10" md="9">
        <b-alert variant="danger" class="mb-1" :show="purchaseQuoteData.status == 'D'">
          <div class="alert-body">
            <span v-if="purchaseQuoteData.notes != null"><strong>Reason for rejection : </strong> {{ purchaseQuoteData.notes }}</span>
            <span v-else><strong>Reason for rejection : </strong> No note has been written!</span>
          </div>
        </b-alert>

        <b-card no-body class="invoice-preview-card table-responsive">
          <span style="padding:10px;"><strong>Quote Number :</strong> #{{ Number($route.params.id) }} <strong> Quote Date :</strong> {{ purchaseQuoteData.quoteDate }}</span>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Seller Identification (The "Seller")</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="purchaseQuoteData.sellerCustomerType == 1 || purchaseQuoteData.sellerCustomerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerCustomerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerCustomerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4" :class="purchaseQuoteData.sellerCustomerName == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerName }}
                </td>
                <td colspan="4">{{ purchaseQuoteData.sellerCustomerLastName }}</td>
              </tr>
              <tr class="title" v-if="purchaseQuoteData.sellerCustomerType == 1 || purchaseQuoteData.sellerCustomerType == null">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue" v-if="purchaseQuoteData.sellerCustomerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerCustomerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="purchaseQuoteData.sellerCustomerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerName == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerName }}
                </td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerLastName }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerContactPerson == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerContactPerson }}
                </td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerContactInfo }}</td>
              </tr>
              <tr class="title" v-if="purchaseQuoteData.sellerCustomerType == 2">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="2" class="noborderright">First Name / Business Name</th>
                <th colspan="2" class="noborderright">Last Name / Operating Name</th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerLicense }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerGst }}</td>
                <td colspan="4" :class="purchaseQuoteData.sellerCustomerPhone == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerPhone }}
                </td>
                <td colspan="4" :class="purchaseQuoteData.sellerCustomerEmail == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerCustomerEmail }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerOtherId }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerPst }}</td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerUnitNumber }}</td>
                <td colspan="6" :class="purchaseQuoteData.sellerCustomerStreet == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerStreet }}
                </td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerDateOfBirth }}&nbsp;</td>
                <td colspan="2">{{ purchaseQuoteData.sellerCustomerDealer }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerCity == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerCustomerCity }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerProvince == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerCustomerProvince }}</td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerPostal == null ? 'bg-light-danger' : ''">
                  {{ purchaseQuoteData.sellerCustomerPostal }}
                </td>
                <td colspan="2" :class="purchaseQuoteData.sellerCustomerCountry == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.sellerCustomerCountry }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card v-if="purchaseQuoteData.coOwnerList.length == 0" no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Owner Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;"> &#9744;</span>
                  Business
                </td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="6"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">&nbsp;</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-card v-for="item in purchaseQuoteData.coOwnerList" :key="item.customerId" no-body class="invoice-preview-card table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">Co-Owner Information</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue" v-if="item.sellerType == 1 || item.sellerType == null">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="4">{{ item.firstName }}</td>
                <td colspan="4">{{ item.lastName }}</td>
              </tr>

              <tr class="textvalue" v-if="item.sellerType == 2">
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 1"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Individual
                </td>
                <td colspan="2">
                  <span style="font-size:180%;" v-if="item.sellerType == 2"> &#9745;</span>
                  <span style="font-size:180%;" v-else> &#9744;</span>
                  Business
                </td>
                <td colspan="2">{{ item.firstName }}</td>
                <td colspan="2">{{ item.lastName }}</td>
                <td colspan="2">{{ item.contactPerson }}</td>
                <td colspan="2">{{ item.contactInfo }}</td>
              </tr>

              <tr class="title" v-if="item.sellerType == 1 || item.sellerType == null">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="4" class="noborderright">First Name / Business Name</th>
                <th colspan="4">Last Name / Operating Name</th>
              </tr>

              <tr class="title" v-if="item.sellerType == 2">
                <th colspan="4" class="noborderright">Seller Type</th>
                <th colspan="2" class="noborderright">First Name / Business Name</th>
                <th colspan="2" class="noborderright">Last Name / Operating Name</th>
                <th colspan="2" class="noborderright">Contact Person</th>
                <th colspan="2">Contact Info</th>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Driver's License Number</th>
                <th colspan="2" class="noborderright">G.S.T Registration</th>
                <th colspan="4" class="noborderright">Phone Number</th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.otherIdNumber }}</td>
                <td colspan="2">{{ item.pst }}</td>
                <td colspan="2">{{ item.unitNumber }}</td>
                <td colspan="6">{{ item.streetAddress }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Other ID Number</th>
                <th colspan="2" class="noborderright">P.S.T Registration</th>
                <th colspan="2" class="noborderright">Unit Number</th>
                <th colspan="6">Street Address</th>
              </tr>

              <tr class="textvalue">
                <td colspan="2">{{ item.dateOfBirth }}</td>
                <td colspan="2">{{ item.dealer }}</td>
                <td colspan="2">{{ item.city }}</td>
                <td colspan="2">{{ item.provence }}</td>
                <td colspan="2">{{ item.postalCode }}</td>
                <td colspan="2">{{ item.country }}</td>
              </tr>

              <tr class="title">
                <th colspan="2" class="noborderright">Date of Birth</th>
                <th colspan="2" class="noborderright">Dealer Registration</th>
                <th colspan="2" class="noborderright">City</th>
                <th colspan="2" class="noborderright">Province</th>
                <th colspan="2" class="noborderright">Postal Code</th>
                <th colspan="2">Country</th>
              </tr>
            </tbody>
          </table>
        </b-card>

        <b-row>
          <b-col cols="12" md="5" xl="5">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Description of Vehicle (The "Vehicle")</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textvalue">
                    <td colspan="6" :class="purchaseQuoteData.vehicleCondition == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleCondition ? purchaseQuoteData.vehicleCondition : '&nbsp;' }}
                    </td>
                    <td colspan="6" :class="purchaseQuoteData.vehicleMake == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMake ? purchaseQuoteData.vehicleMake : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Condition</th>
                    <th colspan="6">Make</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.vehicleYear ? purchaseQuoteData.vehicleYear : '&nbsp;' }}</td>
                    <td colspan="6">{{ purchaseQuoteData.vehicleModel ? purchaseQuoteData.vehicleModel : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Model Year</th>
                    <th colspan="6">Model</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.vehicleFuel ? purchaseQuoteData.vehicleFuel : '&nbsp;' }}</td>
                    <td colspan="6">{{ purchaseQuoteData.vehicleTrim ? purchaseQuoteData.vehicleTrim : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Fuel Type</th>
                    <th colspan="6">Trim</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.vehicleTransmission ? purchaseQuoteData.vehicleTransmission : '&nbsp;' }}</td>
                    <td colspan="6" :class="purchaseQuoteData.vehicleExteriorColor == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleExteriorColor ? purchaseQuoteData.vehicleExteriorColor : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Transmission</th>
                    <th colspan="6">Exterior Colour</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.vehicleDrivetrain ? purchaseQuoteData.vehicleDrivetrain : '&nbsp;' }}</td>
                    <td colspan="6">{{ purchaseQuoteData.vehicleInteriorColor ? purchaseQuoteData.vehicleInteriorColor : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Drivetrain</th>
                    <th colspan="6">Interior Colour</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.vehicleBodyType ? purchaseQuoteData.vehicleBodyType : '&nbsp;' }}</td>
                    <td colspan="4" :class="purchaseQuoteData.vehicleMileage == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMileage ? purchaseQuoteData.vehicleMileage : '&nbsp;' }}
                    </td>
                    <td colspan="4" :class="purchaseQuoteData.vehicleMileageUnit == null ? 'bg-light-danger' : ''">
                      {{ purchaseQuoteData.vehicleMileageUnit ? purchaseQuoteData.vehicleMileageUnit : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">Body Type</th>
                    <th colspan="4" class="noborderright">Mileage</th>
                    <th colspan="4">Mileage Unit</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.vehicleStockNumber ? purchaseQuoteData.vehicleStockNumber : '&nbsp;' }}&nbsp;</td>
                    <td colspan="6" :class="purchaseQuoteData.vehicleVIN == null ? 'bg-light-danger' : ''">{{ purchaseQuoteData.vehicleVIN ? purchaseQuoteData.vehicleVIN : '&nbsp;' }} &nbsp;</td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" class="noborderright">Stock Number</th>
                    <th colspan="6">Vehicle Identification Number</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
          <b-col cols="12" md="7" xl="7">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="10">Vehicle Declearation</th>
                    <th scope="col" colspan="1" class="text-center">YES</th>
                    <th scope="col" colspan="1" class="text-center">NO</th>
                  </tr>
                </thead>
                <tbody class="text-left">
                  <tr class="textlongvalue">
                    <td colspan="10">
                      1. Is the motor vehicle complies with the requirements of the Motor Vehicle Act? If no, the vehicle is sold for parts only or for purposes other than transportation, and this fact has been disclosed to the Purchaser.
                    </td>
                    <td colspan="1" style="height:5em" :class="purchaseQuoteData.vd1 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd1 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd1 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="!purchaseQuoteData.vd1 == 'false'"> &#x2714; </span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10">
                      2. Has the motor vehicle ever been used as a taxi, police vehicle, emergency vehicle or used in organized racing?
                    </td>
                    <td colspan="1" style="height:5em" :class="purchaseQuoteData.vd2 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd2 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd2 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd2 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10">
                      3. Has the motor vehicle ever been used as a lease or rental vehicle?
                    </td>
                    <td colspan="1" style="height:5em" :class="purchaseQuoteData.vd3 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd3 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd3 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd3 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      4. Has the motor vehicle ever sustained damage requiring repairs costing more than $2000.00?
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd4 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd4 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd4 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd4 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      5. Has the motor vehicle ever been registered in any other jurisdiction other than British Columbia? If "Yes", in what jurisdiction(s) has the vehicle been registered: <i>{{ purchaseQuoteData.get_inventory.vd1Text }}</i>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd5 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd5 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd5 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd5 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      6. Has the Vehicle been brought into British Columbia specifically for the purpose of resale?
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd6 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd6 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd6 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd6 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>

                  <tr class="textlongvalue">
                    <td colspan="10" style="height:5em">
                      7. The odometer of the vehicle accurately records the ture distance traveled by the motor vehicle to the best of the dealer's knowledge and belief.
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd7 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd7 == 'true'"> &#x2714; </span>
                    </td>
                    <td colspan="1" :class="purchaseQuoteData.vd7 == null ? 'bg-light-danger' : ''">
                      <span style="font-size:200%;" v-if="purchaseQuoteData.vd7 == 'false'"> &#x2714;</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" xl="6">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Accessories Registration</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textproduct">
                    <th colspan="11" style="width:75%">Accessorie Name</th>
                    <th colspan="1" style="width:25%">Quantity</th>
                  </tr>

                  <tr v-for="item in purchaseQuoteData.get_msrp_options.length + 7" :key="item" class="textproduct">
                    <td colspan="11">{{ purchaseQuoteData.get_accessiore_options[item - 1] ? purchaseQuoteData.get_accessiore_options[item - 1].title : '&nbsp;' }}</td>
                    <td colspan="1">{{ purchaseQuoteData.get_accessiore_options[item - 1] ? purchaseQuoteData.get_accessiore_options[item - 1].quantity : '&nbsp;' }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col cols="12" md="12" xl="6">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">MSRP Configuration</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textproduct">
                    <td colspan="4">{{ purchaseQuoteData.get_inventory.get_origenal_market ? purchaseQuoteData.get_inventory.get_origenal_market.value : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.get_inventory.get_msrp_currency ? purchaseQuoteData.get_inventory.get_msrp_currency.value : '&nbsp;' }}</td>
                    <td colspan="4" v-if="purchaseQuoteData.get_inventory.baseMsrp != null">{{ formatPrice(purchaseQuoteData.get_inventory.baseMsrp) }}</td>
                    <td colspan="4" v-else>&nbsp;</td>
                  </tr>

                  <tr class="textproduct">
                    <th colspan="4" class="noborderright">Original Market Specs</th>
                    <th colspan="4" class="noborderright">Base MSRP Currency</th>
                    <th colspan="4">Base MSRP</th>
                  </tr>

                  <tr class="textproduct">
                    <th colspan="11" style="width:75%">Option Name</th>
                    <th colspan="1" style="width:25%;">Option Price</th>
                  </tr>

                  <tr v-for="item in purchaseQuoteData.get_msrp_options.length + 3" :key="item" class="textproduct">
                    <td colspan="11">{{ purchaseQuoteData.get_msrp_options[item - 1] ? purchaseQuoteData.get_msrp_options[item - 1].title : '&nbsp;' }}</td>
                    <td colspan="1">{{ purchaseQuoteData.get_msrp_options[item - 1] ? formatPrice(purchaseQuoteData.get_msrp_options[item - 1].price) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="textproduct">
                    <th colspan="11" class="text-right">Total MSRP Options Price</th>
                    <th colspan="1">
                      {{ formatPrice(purchaseQuoteData.totalMsrpOptions) }}
                    </th>
                  </tr>
                  <tr class="textproduct">
                    <th colspan="11" class="text-right"><strong>Total Vehicle MSRP</strong></th>
                    <th colspan="1">{{ formatPrice(purchaseQuoteData.totalMsrpOptions + (purchaseQuoteData.get_inventory ? purchaseQuoteData.get_inventory.baseMsrp : 0)) }}</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Vehicle Appraisal</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="textvalue">
                    <td colspan="1" style="width:10%" :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 1" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4" style="width:20%">Clean Owned</td>
                    <td colspan="7" rowspan="5" class="text-left align-top" style="width:70%">
                      {{ purchaseQuoteData.appraisalNotes }}
                    </td>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="1" :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 2" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4">Owned with Lien Payout</td>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="1" :class="purchaseQuoteData.vehicleTitleType == null ? 'bg-light-danger' : ''">
                      <span v-if="purchaseQuoteData.vehicleTitleType == 3" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="4">Leased</td>
                  </tr>

                  <tr class="title">
                    <th colspan="5">Vehicle Title Type</th>
                  </tr>

                  <tr class="textvalue" :class="purchaseQuoteData.appraisedVehiclePrice == null ? 'bg-light-danger' : ''">
                    <td colspan="5">{{ purchaseQuoteData.appraisedVehiclePrice ? formatPrice(purchaseQuoteData.appraisedVehiclePrice) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="5">Appraised Vehicle Price</th>
                    <th colspan="7">Appraisal Notes</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4" xl="4">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Lien Payout</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:10%">
                      <span v-if="purchaseQuoteData.payoutType == 1" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="11" style="width:90%">Finance Payout</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span v-if="purchaseQuoteData.payoutType == 2" style="font-size:180%;">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="11">Lease Payout</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Payout Type</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">{{ purchaseQuoteData.beforeTax ? formatPrice(purchaseQuoteData.beforeTax) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Net Payout Before Tax</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="6">{{ purchaseQuoteData.gstRate ? formatPrice(purchaseQuoteData.gstRate) + '%' : '&nbsp;' }}</td>
                    <td colspan="6">
                      {{ purchaseQuoteData.beforeTax ? formatPrice((purchaseQuoteData.beforeTax * (purchaseQuoteData.gstRate ? purchaseQuoteData.gstRate : 0)) / 100) : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="6" style="width:40%">G.S.T Rate</th>
                    <th colspan="6" style="width:60%">G.S.T Amount</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">
                      {{ purchaseQuoteData.beforeTax ? formatPrice((purchaseQuoteData.beforeTax * (purchaseQuoteData.gstRate ? purchaseQuoteData.gstRate : 0)) / 100 + purchaseQuoteData.beforeTax) : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Total Payout Amount</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="12">{{ purchaseQuoteData.unitlDate ? purchaseQuoteData.unitlDate : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="12">Good Until Date</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>

          <b-col cols="12" md="8" xl="8">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Lien Holder Information</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:3%">
                      <span style="font-size:180%;" v-if="(purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.sellerType : 0) == 1">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:21%">Individual</td>

                    <td colspan="4" rowspan="2">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.firstName : '&nbsp;' }}</td>
                    <td colspan="4" rowspan="2">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.lastName : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;" v-if="(purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.sellerType : 0) == 2">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Business</td>
                  </tr>
                  <tr class="title">
                    <th colspan="4" class="noborderright" style="width:24%">Seller Type</th>
                    <th colspan="4" class="noborderright" style="width:38%">First Name / Business Name</th>
                    <th colspan="4" style="width:38%">Last Name / Operating Name</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.driverLicenseNumber : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.phoneNumber : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.email : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">Driver's License Number</th>
                    <th colspan="4" class="noborderright">Phone Number</th>
                    <th colspan="4">Email</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.gst : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.unitNumber : '&nbsp;' }}</td>
                    <td colspan="4" rowspan="3" class="text-left align-top">
                      {{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.streetAddress : '&nbsp;' }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">G.S.T Registration</th>
                    <th colspan="4">Unit Number</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.pst : '&nbsp;' }}</td>
                    <td colspan="4">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.city : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" class="noborderright">P.S.T Registration</th>
                    <th colspan="4" class="noborderright">City</th>
                    <th colspan="4">Street Address</th>
                  </tr>

                  <tr class="textvalue">
                    <td colspan="3">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.dealer : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.provence : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.postalCode : '&nbsp;' }}</td>
                    <td colspan="3">{{ purchaseQuoteData.lienHolderList ? purchaseQuoteData.lienHolderList.country : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="3" class="noborderright">Dealer Registration</th>
                    <th colspan="3" class="noborderright">Province</th>
                    <th colspan="3" class="noborderright">Postal Code</th>
                    <th colspan="3">Country</th>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Price Calculation</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="1" style="width:1%">
                      <span style="font-size:180%;" v-if="(purchaseQuoteData.priceContractType ? purchaseQuoteData.priceContractType : 0) == 1">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3" style="width:19%">Buy-in</td>
                    <td colspan="4" style="width:40%; border-bottom:hidden"></td>
                    <th colspan="3" style="width:20%" class="text-right">Price Of Vehicle</th>
                    <td colspan="1" style="width:10%">{{ purchaseQuoteData.appraisedVehiclePrice ? formatPrice(purchaseQuoteData.appraisedVehiclePrice) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;" v-if="(purchaseQuoteData.priceContractType ? purchaseQuoteData.priceContractType : 0) == 2">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Trade-in</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="3" class="text-right">Trade-in Credit Issued</td>
                    <td colspan="1">{{ formatPrice(creditIssued) ? formatPrice(creditIssued) : '&nbsp;' }}</td>
                  </tr>

                  <tr class="title">
                    <td colspan="1">
                      <span style="font-size:180%;" v-if="(purchaseQuoteData.priceContractType ? purchaseQuoteData.priceContractType : 0) == 3">&#x2611;</span>
                      <span v-else style="font-size:180%;">&#x2610;</span>
                    </td>
                    <td colspan="3">Lease Return (in-house)</td>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="3" class="text-right">Price Difference</td>
                    <td colspan="1">{{ formatPrice(priceDifference) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4">Contract Type</th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="3" class="text-right">G.S.T Rate on Vehicle</td>
                    <td colspan="1">{{ purchaseQuoteData.gstRateVehicle ? formatPrice(purchaseQuoteData.gstRateVehicle) + '%' : '0.00' }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" style=" border-right:hidden; border-left:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <td colspan="3" class="text-right">G.S.T Amount on Vehicle</td>
                    <td colspan="1">
                      {{ formatPrice(gstAmountOnVehicle) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <th colspan="4" style=" border:hidden"></th>
                    <td colspan="4" style=" border-bottom:hidden"></td>
                    <th colspan="3" class="text-right">Total Vehicle Purchase Price</th>
                    <td colspan="1">
                      {{ formatPrice(priceDifference + gstAmountOnVehicle) }}
                    </td>
                  </tr>
                  <br /><br />
                  <tr class="title">
                    <th colspan="4" style="width:20%;">Additional Purchase Options</th>
                    <th colspan="2" style="width:15%;">Option Price</th>
                    <td colspan="1" style="width:10%;">G.S.T Rate</td>
                    <td colspan="1" style="width:15%;">G.S.T Amount</td>
                    <td colspan="1" style="width:10%;">P.S.T Rate</td>
                    <td colspan="1" style="width:15%;">P.S.T Amount</td>
                    <td colspan="2" style="width:15%;">Option Sub-Total</td>
                  </tr>

                  <tr class="title" v-for="item in purchaseQuoteData.get_additional" :key="item.id">
                    <td colspan="4">{{ item.title }}</td>
                    <td colspan="2">{{ item.price ? formatPrice(item.price) : '' }}</td>
                    <td colspan="1">{{ item.gst ? formatPrice(item.gst) : '' }}%</td>
                    <td colspan="1">{{ item.price ? formatPrice((item.price * (item.gst ? item.gst : 0)) / 100) : '' }}</td>
                    <td colspan="1">{{ item.pst ? formatPrice(item.pst) : '' }}%</td>
                    <td colspan="1">{{ item.price ? formatPrice((item.price * (item.pst ? item.pst : 0)) / 100) : '' }}</td>
                    <td colspan="2">
                      {{ formatPrice((item.price ? item.price : 0) + (item.price * (item.gst ? item.gst : 0)) / 100 + (item.price * (item.pst ? item.pst : 0)) / 100) }}
                    </td>
                  </tr>

                  <tr class="title" v-for="item in 3" :key="item.id">
                    <th colspan="4">&nbsp;</th>
                    <th colspan="2">&nbsp;</th>
                    <td colspan="1">&nbsp;</td>
                    <td colspan="1">&nbsp;</td>
                    <td colspan="1">&nbsp;</td>
                    <td colspan="1">&nbsp;</td>
                    <td colspan="2">&nbsp;</td>
                  </tr>

                  <br /><br />
                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Total Additional Purchase Option Amount</td>
                    <td colspan="2">{{ purchaseQuoteData.priceCalculationValues ? formatPrice(purchaseQuoteData.priceCalculationValues.purchaeOptionAmount) : 0.0 }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Total Purchase Contract Amount</th>
                    <td colspan="2">
                      {{ formatPrice(contractAmount) }}
                    </td>
                  </tr>

                  <tr class="title">
                    <td colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;"><i>Less Net Payout Before Tax</i></td>
                    <td colspan="2">{{ formatPrice(purchaseQuoteData.beforeTax * -1) }}</td>
                  </tr>

                  <tr class="title">
                    <th colspan="10" class="text-right" style="border-top:hidden; border-left:hidden;">Purchase Contract Balance Due</th>
                    <td colspan="2">
                      {{ formatPrice(contractAmount - (purchaseQuoteData.beforeTax ? purchaseQuoteData.beforeTax : 0)) }}
                    </td>
                  </tr>

                  <tr class="title" v-if="contractAmount - (purchaseQuoteData.beforeTax ? purchaseQuoteData.beforeTax : 0) < 0">
                    <th colspan="10" class="text-right text-danger" style="border-top:hidden; border-left:hidden;">Payable By Seller</th>
                    <td colspan="2">
                      {{ formatPrice((contractAmount - (purchaseQuoteData.beforeTax ? purchaseQuoteData.beforeTax : 0)) * -1) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Comments, Promises, Restrictions, Limitations and Conditions</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="title">
                    <td colspan="12" style="height:250px; font-size:14px; text-align:left; vertical-align:top">
                      {{ purchaseQuoteData.comments }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card no-body class="invoice-preview-card table-responsive">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Vehicle Appraisal Photos</th>
                  </tr>
                </thead>
              </table>

              <b-container fluid class="p-2">
                <b-row v-if="purchaseQuoteData.inventoryGallery.length > 0">
                  <b-col xs="12" sm="6" md="4" lg="3" class="mb-2" v-for="image in purchaseQuoteData.inventoryGallery" :key="image.item">
                    <b-media-aside>
                      <b-img thumbnail fluid ref="refPreviewEl" @click="imageClick(baseURL + image)" :src="baseURL + image" style="cursor:zoom-in" />
                    </b-media-aside>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="12" md="8">
            <b-card no-body>
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" colspan="12">Payment Details</th>
                  </tr>
                </thead>
              </table>

              <b-table striped hover :bordered="true" class="text-center mt-2 pr-2 pl-2" selectable responsive :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="paymentFields" :items="purchaseQuoteData.payments">
                <template #cell(id)="row">
                  <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
                </template>

                <template #cell(invoiceBalance)="row">
                  <span>{{ row.item.invoiceBalance ? formatPrice(row.item.invoiceBalance) : 'N/A' }}</span>
                </template>

                <template #cell(paymentAmount)="row">
                  <span>{{ row.item.paymentAmount ? formatPrice(row.item.paymentAmount) : 'N/A' }}</span>
                </template>

                <template #cell(email)="row">
                  <span>{{ row.item.email ? row.item.email : 'N/A' }}</span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" xl="2" md="3" class="invoice-actions">
        <b-card v-if="purchaseQuoteData.tradeStatus != 1 && purchaseQuoteData.tradeStatus != 2">
          <!-- Button: Send Invoice -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_create')" v-if="purchaseQuoteData.status == 'A' && userRole != 'supermanager'" @click="statusChange('B')" variant="primary" class="mb-75" block>
            Send to Manager
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_approve')" v-if="purchaseQuoteData.status == 'B' || purchaseQuoteData.status == 'A'" @click="statusChange('C')" variant="success" class="mb-75" block>
            Approve the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_refuse')" v-if="purchaseQuoteData.status == 'B' || purchaseQuoteData.status == 'A'" @click="statusChange('D')" variant="danger" class="mb-75" block>
            Refuse the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_send_client')" v-if="purchaseQuoteData.status == 'C'" @click="statusChange('E')" variant="info" class="mb-75" block>
            Send to Client
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_bypass_client')" v-if="(purchaseQuoteData.status == 'E' || purchaseQuoteData.status == 'C') && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('F')" variant="success" class="mb-75" block>
            Mark as Won
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="(purchaseQuoteData.status == 'E' || purchaseQuoteData.status == 'C') && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'blank'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'notapprove'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'notapprove'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>
          <!-- finish -->

          <!-- Client Approved  -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_won')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'approve'" @click="statusChange('F')" variant="success" class="mb-75" block>
            Mark as Won
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'E' && purchaseQuoteData.statusCustomer == 'approve'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>
          <!-- finish -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_mark_lost')" v-if="purchaseQuoteData.status == 'D'" @click="statusChange('MarkAsLost')" variant="danger" class="mb-75" block>
            Mark as Lost
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_revise')" v-if="purchaseQuoteData.status == 'D'" @click="statusChange('R')" variant="warning" class="mb-75" block>
            Revise the Quote
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-show="$Can('purchase_quote_mark_complete')"
            v-if="
              purchaseQuoteData.status == 'F' &&
                (purchaseQuoteData.get_inventory.status == 'G' ||
                  purchaseQuoteData.get_inventory.status == 'PA' ||
                  purchaseQuoteData.get_inventory.status == 'PB' ||
                  purchaseQuoteData.get_inventory.status == 'PC' ||
                  purchaseQuoteData.get_inventory.status == 'PD' ||
                  purchaseQuoteData.get_inventory.status == 'PH' ||
                  purchaseQuoteData.get_inventory.status == 'PI' ||
                  purchaseQuoteData.get_inventory.status == 'PE' ||
                  purchaseQuoteData.get_inventory.status == 'PF' ||
                  purchaseQuoteData.get_inventory.status == 'PK') &&
                purchaseQuoteData.payment == 'yes'
            "
            @click="statusChange('MarkComplete')"
            variant="success"
            class="mb-75"
            block
          >
            Complete
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-show="$Can('mark_payment_made')"
            v-if="
              purchaseQuoteData.payment == 'no' &&
                (purchaseQuoteData.get_inventory.status == 'I' || purchaseQuoteData.get_inventory.status == 'F' || purchaseQuoteData.get_inventory.status == 'PF' || purchaseQuoteData.get_inventory.status == 'PA' || purchaseQuoteData.get_inventory.status == 'PK') &&
                purchaseQuoteData.status != 'P' &&
                purchaseQuoteData.status != 'J' &&
                this.purchaseQuoteData.leftTotal > 0
            "
            v-b-toggle.sidebar-purchase-add-payment
            variant="success"
            class="mb-75"
            block
          >
            Payment Made
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="$Can('purchase_quote_cancel') && this.where == 'quote-preview'" v-if="purchaseQuoteData.status == 'F' || purchaseQuoteData.status == 'H'" @click="statusChange('MarkCancel')" variant="danger" class="mb-75" block>
            Cancel
          </b-button>

          <!-- Button: DOwnload -->
          <b-overlay :show="download" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="pdfDownload($route.params.id)" variant="secondary" class="mb-75" block>
              Save as Pdf
            </b-button>
          </b-overlay>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-show="$Can('purchase_quote_edit')" v-if="purchaseQuoteData.status == 'A'" variant="outline-primary" class="mb-75" block :to="{ name: 'quote-edit', params: { id: $route.params.id } }">
            Edit
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="purchaseQuoteData.archive != 'yes'" @click="goToVehicle(purchaseQuoteData.inventoryId)" variant="info" class="mb-75" block>
            Go to Vehicle
          </b-button>
        </b-card>

        <b-card v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-show="$Can('mark_payment_made')"
            v-if="
              purchaseQuoteData.payment == 'no' &&
                (purchaseQuoteData.get_inventory.status == 'I' || purchaseQuoteData.get_inventory.status == 'F' || purchaseQuoteData.get_inventory.status == 'PF' || purchaseQuoteData.get_inventory.status == 'PA' || purchaseQuoteData.get_inventory.status == 'PK') &&
                purchaseQuoteData.status != 'P' &&
                purchaseQuoteData.tradeStatus != 2 &&
                this.purchaseQuoteData.leftTotal > 0
            "
            v-b-toggle.sidebar-purchase-add-payment
            variant="success"
            class="mb-75"
            block
          >
            Payment Made
          </b-button>

          <b-overlay :show="download" spinner-variant="primary" spinner-type="grow" spinner-small>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" @click="pdfDownload($route.params.id)" variant="secondary" class="mb-75" block>
              Save as Pdf
            </b-button>
          </b-overlay>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="purchaseQuoteData.archive != 'yes'" @click="goToVehicle(purchaseQuoteData.inventoryId)" variant="info" class="mb-75" block>
            Go to Vehicle
          </b-button>
        </b-card>

        <b-card v-show="where == 'quote-preview'">
          <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary" v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'"> Add New File </b-button> -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary"> Add New File </b-button>

          <!-- user suggestion  -->
          <div v-for="(data, index) in files" :key="index" class="d-flex justify-content-start align-items-center" :class="index == 0 ? 'mt-2' : 'mt-1'">
            <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="25px" />
            <div class="user-page-info pr-1" style="width: 100%">
              <small @click="fileEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
            </div>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm">
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
            No Files
          </div>
        </b-card>

        <app-timeline class="mt-2">
          <app-timeline-item v-for="item in purchaseQuoteData.get_timelines" :key="item.id" :title="item.title" :subtitle="'By ' + item.username" :icon="varicon(item.status)" :time="dateFormat(item.created_at)" :variant="varyant(item.status)" />
        </app-timeline>
      </b-col>
    </b-row>

    <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
        <b-form-group :state="fileState" invalid-feedback="File is required">
          <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
        </b-form-group>
      </b-form>
      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>

    <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
      <b-form ref="fileRules">
        <b-form-group :state="nameState" invalid-feedback="File Name is required">
          <label for="fileName">File Name:</label>
          <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
        </b-form-group>
      </b-form>
      <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
        <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
      </b-col>
    </b-modal>

    <sidebar-purchase-quote-add-payment :formShow.sync="formShow" :refreshQuote="refreshQuote" :purchaseQuoteData.sync="purchaseQuoteData" />
  </b-overlay>
</template>

<script>
import { VBTooltip, BImg, BMediaAside, BContainer, BBadge, BOverlay, BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BFormCheckbox } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import Logo from '@core/layouts/components/QuoteLogo.vue';
import store from '@/store';
import purchaseStoreModule from '../purchaseStoreModule';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import SidebarPurchaseQuoteAddPayment from './SidebarPurchaseQuoteAddPayment.vue';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  components: {
    VBTooltip,
    BImg,
    BMediaAside,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BOverlay,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    Logo,
    SidebarPurchaseQuoteAddPayment,
  },

  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'purchase';
    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      where: router.currentRoute.name,
      files: [],
      modalId: null,
      modalFileName: null,
      modalFile: null,
      userRole: JSON.parse(localStorage.getItem('userData')).role,
      formShow: true,
      purchaseQuoteData: { leftTotal: null },
      notApprove: '',
      download: false,
      baseURL: store.state.app.baseURL,
      fileURL: store.state.app.fileURL,
      percentCompleted: 0,

      paymentFields: [
        { key: 'id', label: 'PAYMENT ID', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'paymentDate', label: 'DATE', class: 'text-center', thStyle: 'width: 15%' },
        { key: 'invoiceBalance', label: 'BALANCE', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'paymentAmount', label: 'PAYMENT AMOUNT', class: 'text-center', thStyle: 'width: 25%' },
        { key: 'paymentMethod', label: 'PAYMENT METHOD', class: 'text-center', thStyle: 'width: 25%' },
      ],
    };
  },

  created() {
    this.refreshQuote();
  },

  computed: {
    creditIssued() {
      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice);
      } else {
        return (this.purchaseQuoteData.creditIssued = 0);
      }
    },

    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },

    priceDifference() {
      //purchaseQuoteData.get_inventory.appraisedVehiclePrice

      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.priceDifference = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      } else {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      }
    },

    gstAmountOnVehicle() {
      if (this.priceDifference == 0) {
        return 0;
      } else if (this.purchaseQuoteData.gstRateVehicle == null || this.purchaseQuoteData.gstRateVehicle == '') {
        return 0;
      } else {
        return Number((this.priceDifference / 100) * this.purchaseQuoteData.gstRateVehicle);
      }
    },

    optionAmountTotal() {
      var total = 0;
      this.purchaseQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcSubTotal);
      });

      return total;
    },

    contractAmount() {
      return Number(this.optionAmountTotal) + Number(this.priceDifference) + Number(this.gstAmountOnVehicle);
    },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    goToVehicle(val) {
      router.push({ name: 'inventory-view', params: { id: val } });
    },

    fileEdit(data) {
      this.modalId = data.id;
      // v-if="userRole != 'supermanager'" v-show="purchaseQuoteData.status == 'A' || purchaseQuoteData.status == 'B'"
      if (this.userRole == 'supermanager') {
        this.modalFileName = data.fileName;

        this.$bvModal.show('modal-file-update');
      } else if (this.purchaseQuoteData.status == 'A' || this.purchaseQuoteData.status == 'B') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deletePurchaseFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.percentCompleted == 100) {
        var data = {
          quoteId: this.purchaseQuoteData.id,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };
        // Trigger submit handler
        this.modalOk(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;

      axiosIns
        .post('savePurchaseFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updatePurchaseFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadPurchaseFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    isEmptyOrSpaces(str) {
      return str === null || str == '' || str == ' ';
    },

    varyant(val) {
      if (val == 'A') {
        return 'success';
      } else if (val == 'B') {
        return 'info';
      } else if (val == 'C') {
        return 'success';
      } else if (val == 'D') {
        return '';
      } else if (val == 'E') {
        return 'warning';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'F') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'F') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    statusChange(statusVal) {
      if (statusVal == 'D') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          inputPlaceholder: 'Reason for rejecting the quote?',
          icon: 'warning',
          input: 'textarea',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, reject the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
              notes: result.value,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'MarkAsLost') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: textValue,
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, mark as lost!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else if (statusVal == 'R') {
        this.$swal({
          title: 'Are you sure you want to continue?',
          text: 'The status of the quote will be changed to draft.',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Yes, revise the quote!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            var value = {
              id: router.currentRoute.params.id,
              value: statusVal,
            };
            this.updateStatus(value);
          }
        });
      } else {
        var notemptyInventory =
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.condition) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.mileageUnit) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.mileageValue) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.extColour) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vinNo);

        var notemptyDeclearation =
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd1) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd2) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd3) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd4) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd5) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd6) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_inventory.vd7);

        var notemptyCustomer =
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.sellerType) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.phoneNumber) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.streetAddress) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.email) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.provence) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.postalCode) ||
          this.isEmptyOrSpaces(this.purchaseQuoteData.get_seller_customer.country);

        if (this.purchaseQuoteData.get_lien_customer) {
          var notemptyLienHolder =
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.sellerType) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.phoneNumber) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.streetAddress) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.email) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.provence) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.postalCode) ||
            this.isEmptyOrSpaces(this.purchaseQuoteData.get_lien_customer.country);
        }

        var notemptyQuote = this.isEmptyOrSpaces(this.purchaseQuoteData.vehicleTitleType) || this.isEmptyOrSpaces(this.purchaseQuoteData.completionDate) || this.isEmptyOrSpaces(this.purchaseQuoteData.quoteDate) || this.isEmptyOrSpaces(this.purchaseQuoteData.appraisedVehiclePrice);

        if (this.purchaseQuoteData.vehicleTitleType == 1 || this.purchaseQuoteData.vehicleTitleType == '1') {
          var condition = notemptyInventory || notemptyCustomer || notemptyQuote || notemptyDeclearation;
        } else {
          var condition = notemptyInventory || notemptyCustomer || notemptyQuote || notemptyDeclearation || notemptyLienHolder;
        }

        if (condition) {
          this.$swal({
            title: 'Fill in the missing fields!',
            text: 'You must fill in the mandatory fields before submitting it for manager approval.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Edit',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if (notemptyInventory) {
                router.push({ name: 'inventory-edit-purchase', params: { id: this.purchaseQuoteData.inventoryId, purchaseid: this.purchaseQuoteData.id } });
              } else if (notemptyDeclearation) {
                router.push({ name: 'vehicle-purchase-decleration', params: { id: this.purchaseQuoteData.inventoryId, quoteid: this.purchaseQuoteData.id } });
              } else if (notemptyCustomer) {
                router.push({ name: 'customer-edit-purchase', params: { id: this.purchaseQuoteData.sellerCustomerId, quoteid: this.purchaseQuoteData.id } });
              } else if (notemptyQuote) {
                router.push({ name: 'quote-validation-edit', params: this.purchaseQuoteData.id });
              } else if (notemptyLienHolder) {
                router.push({ name: 'customer-edit-purchase', params: { id: this.purchaseQuoteData.get_lien_customer.id, quoteid: this.purchaseQuoteData.id } });
              } else {
              }

              //    :to="{ name: 'quote-edit', params: { id: $route.params.id } }
            }
          });
        } else {
          var buttonText = 'Yes, send to manager!';
          var textValue = "You won't be able to revert this!";
          if (statusVal == 'B') {
            buttonText = 'Yes, send to manager!';
          } else if (statusVal == 'C') {
            buttonText = 'Yes, approve the quote!';
          } else if (statusVal == 'D') {
            buttonText = 'Yes, reject the quote!';
          } else if (statusVal == 'E') {
            buttonText = 'Yes, send to client!';
          } else if (statusVal == 'F') {
            buttonText = 'Yes, add the vehicle to inventory!';
          } else if (statusVal == 'P') {
            buttonText = 'Yes, mark as lost!';
          } else if (statusVal == 'R') {
            buttonText = 'Yes, revise the quote!';
            textValue = 'The status of the quote will be changed to draft.';
          } else if (statusVal == 'PM') {
            buttonText = 'Yes, payment made!';
            textValue = 'The status of the quote will be changed to paid';
          } else if (statusVal == 'MarkAsLost') {
            buttonText = 'Yes, mark as lost!';
          } else if (statusVal == 'MarkCancel') {
            buttonText = 'Yes, mark as Cancel!';
          } else if (statusVal == 'MarkComplete') {
            buttonText = 'Yes, mark as Complete!';
          }

          this.$swal({
            title: 'Are you sure you want to continue?',
            text: textValue,
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: buttonText,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
            .then((result) => {
              if (result.isConfirmed) {
                var value = {
                  id: router.currentRoute.params.id,
                  value: statusVal,
                };
                this.updateStatus(value);
              }
            })
            .catch((error) => {
              console.log(error);
              this.formShow = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong',
                  text: 'Please try again or report an issue to support',
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              });
            });
        }
      }
    },

    refreshQuote() {
      if (this.where == 'quote-preview') {
        this.getByIdQuote('getPreviewQuoteById');
      } else {
        this.getByIdQuote('getArchivePreviewQuoteById');
      }
    },

    updateStatus(val) {
      this.formShow = true;
      val.email = this.purchaseQuoteData.sellerIdentification.email;
      val.updated_at = this.purchaseQuoteData.updated_at;

      var resultText = 'Your quote has been sent to manager';
      if (val.value == 'B') {
        resultText = 'Your quote has been sent to manager';
      } else if (val.value == 'C') {
        resultText = 'The quote has been approved';
      } else if (val.value == 'D') {
        resultText = 'The quote has been rejected';
      } else if (val.value == 'E') {
        resultText = 'The quote has been sent to client';
      } else if (val.value == 'F') {
        resultText = 'Vehicle has been added to inventory';
      } else if (val.value == 'P') {
        resultText = 'The Quote has been marked as lost';
      } else if (val.value == 'R') {
        resultText = 'The Quote has been changed as draft';
      } else if (val.value == 'PM') {
        resultText = 'The Quote has been changed as payment made';
      } else if (val.value == 'MarkAsLost') {
        resultText = 'The Quote has been marked as lost';
      } else if (val.value == 'MarkCancel') {
        resultText = 'The Quote has been marked as cancel';
      } else {
        resultText = '--';
      }

      store
        .dispatch('purchase/updateQuoteStatus', val)
        .then((response) => {
          if (response.data != 'alreadyUpdated') {
            this.formShow = false;
            this.$swal({
              icon: 'success',
              title: 'Successful!',
              text: resultText,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });

            if (this.where == 'quote-preview') {
              this.getByIdQuote('getPreviewQuoteById');
            } else {
              this.getByIdQuote('getArchivePreviewQuoteById');
            }
          } else {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please refresh the page.',
                text: '⚠️ Data has been changed during operations. ',
                icon: 'RefreshCwIcon',
                variant: 'warning',
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$swal({
            icon: 'error',
            title: 'Something went wrong!',
            text: 'Please try again or report an issue to support',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    imageClick(url) {
      this.$swal({
        imageUrl: url,
        imageWidth: 1000,
        imageHeight: 600,
        showConfirmButton: false,
        width: '1000px',
      });
    },

    pdfDownload(val) {
      this.download = true;
      var link = 'pdfDownload';
      if (this.where == 'quote-preview') {
        link = 'pdfDownload';
      } else {
        link = 'pdfArchiveDownload';
      }
      store.dispatch('purchase/' + link, val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if (this.where == 'quote-preview') {
            link.setAttribute('download', 'Quote_' + Number(val) + '.pdf');
          } else {
            link.setAttribute('download', 'Archive_Quote_' + Number(val) + '.pdf');
          }

          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Quote pdf is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },

    getByIdQuote(val) {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store
          .dispatch('purchase/' + val, { id: router.currentRoute.params.id })
          .then((response) => {
            if (this.where == 'archive-quote-preview') {
              response.data.get_inventory = response.data.get_archive_inventory;
            }

            this.msrpOptions = response.data.get_msrp_options;
            response.data.msrpOptions = this.msrpOptions;
            this.accessioreOption = response.data.get_accessiore_options;
            response.data.accessioreOptions = this.accessioreOption;
            response.data.inventoryGallery = [];
            (response.data.coOwner = []),
              //   this.purchaseQuoteData = this.purchaseQuoteDataModel;
              response.data.get_images.forEach((element) => {
                response.data.inventoryGallery.push(element.name);
              });

            response.data.priceCalculationValues = [];
            response.data.priceCalculationValues.purchaeOptionAmount = 0;
            response.data.get_additional.forEach((element) => {
              response.data.priceCalculationValues.push({
                pcOptions: element.title,
                pcPrice: element.price,
                pcGst: element.gst,
                pcPst: element.pst,
                pcGstAmount: (element.price / 100) * element.gst,
                pcPstAmount: (element.price / 100) * element.pst,
                pcSubTotal: element.price + (element.price / 100) * element.gst + (element.price / 100) * element.pst,
              });

              response.data.priceCalculationValues.purchaeOptionAmount += Number(element.price) + (element.price / 100) * element.gst + (element.price / 100) * element.pst;
            });

            response.data.lienHolder = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,

              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };
            response.data.sellerIdentification = {
              sellerType: null,
              phoneNumber: null,
              firstName: null,
              lastName: null,
              email: null,

              unitNumber: null,
              provence: null,
              streetAddress: null,
              city: null,
              postalCode: null,
              country: null,
              gst: null,
              pst: null,
              dealer: null,
              driverLicenseNumber: null,
              otherIdNumber: null,
              dateOfBirth: null,
              contactPerson: null,
              contactInfo: null,
            };

            response.data.coOwnerList.forEach((element, index) => {
              response.data.coOwner[index] = {
                sellerType: element.sellerType,
                phoneNumber: null,
                firstName: null,
                lastName: null,
                email: null,

                unitNumber: null,
                provence: null,
                streetAddress: null,
                city: null,
                postalCode: null,
                country: null,
                gst: null,
                pst: null,
                dealer: null,
                driverLicenseNumber: null,
                otherIdNumber: null,
                dateOfBirth: null,
                contactPerson: null,
                contactInfo: null,
              };
            });

            this.purchaseQuoteData = response.data;
            this.notApprove = this.purchaseQuoteData.notes;

            this.purchaseQuoteData.vehicleDeclearation = [response.data.vd1, response.data.vd2, response.data.vd3, response.data.vd4, response.data.vd5, response.data.vd6, response.data.vd7];
            this.purchaseQuoteData.vehicleDeclearationText = response.data.vd1Text;
            this.purchaseQuoteData.lienPayout = {
              beforeTax: response.data.beforeTax,
              unitlDate: response.data.unitlDate,
              gstRate: response.data.gstRate,
              gstAmount: null,
              totalAmount: null,
            };

            if (response.data.get_lien_customer == null) {
              this.purchaseQuoteData.lienHolder = {
                sellerType: null,
                phoneNumber: null,
                firstName: null,
                lastName: null,
                email: null,

                unitNumber: null,
                provence: null,
                streetAddress: null,
                city: null,
                postalCode: null,
                country: null,
                gst: null,
                pst: null,
                dealer: null,
                driverLicenseNumber: null,
                otherIdNumber: null,
                dateOfBirth: null,
                contactPerson: null,
                contactInfo: null,
              };
            } else {
              //this.lienCustomerSelect(response.data.get_lien_customer.id);
              this.purchaseQuoteData.lienHolder.sellerType = response.data.get_lien_customer.sellerType;
              this.purchaseQuoteData.lienHolder.phoneNumber = response.data.get_lien_customer.phoneNumber;
              this.purchaseQuoteData.lienHolder.firstName = response.data.get_lien_customer.firstName;
              this.purchaseQuoteData.lienHolder.lastName = response.data.get_lien_customer.lastName;
              this.purchaseQuoteData.lienHolder.email = response.data.get_lien_customer.email;

              this.purchaseQuoteData.lienHolder.unitNumber = response.data.get_lien_customer.unitNumber;
              this.purchaseQuoteData.lienHolder.provence = response.data.get_lien_customer.provence;
              this.purchaseQuoteData.lienHolder.streetAddress = response.data.get_lien_customer.streetAddress;
              this.purchaseQuoteData.lienHolder.city = response.data.get_lien_customer.city;
              this.purchaseQuoteData.lienHolder.postalCode = response.data.get_lien_customer.postalCode;
              this.purchaseQuoteData.lienHolder.country = response.data.get_lien_customer.country;
              this.purchaseQuoteData.lienHolder.gst = response.data.get_lien_customer.gst;
              this.purchaseQuoteData.lienHolder.pst = response.data.get_lien_customer.pst;
              this.purchaseQuoteData.lienHolder.dealer = response.data.get_lien_customer.dealer;
              this.purchaseQuoteData.lienHolder.customerId = response.data.get_lien_customer.id;

              this.purchaseQuoteData.lienHolder.driverLicenseNumber = response.data.get_lien_customer.driverLicenseNumber;
              this.purchaseQuoteData.lienHolder.firstotherIdNumberName = response.data.get_lien_customer.firstotherIdNumberName;
              this.purchaseQuoteData.lienHolder.dateOfBirth = response.data.get_lien_customer.dateOfBirth;
              this.purchaseQuoteData.lienHolder.contactPerson = response.data.get_lien_customer.contactPerson;
              this.purchaseQuoteData.lienHolder.contactInfo = response.data.get_lien_customer.contactInfo;
            }

            if (response.data.get_seller_customer == null) {
              this.purchaseQuoteData.sellerIdentification = {
                sellerType: null,
                phoneNumber: null,
                firstName: null,
                lastName: null,
                email: null,

                unitNumber: null,
                provence: null,
                streetAddress: null,
                city: null,
                postalCode: null,
                country: null,
                gst: null,
                pst: null,
                dealer: null,
                driverLicenseNumber: null,
                otherIdNumber: null,
                dateOfBirth: null,
                contactPerson: null,
                contactInfo: null,
              };
            } else {
              //   this.sellerCustomerSelect(response.data.get_seller_customer.id);
              this.purchaseQuoteData.sellerIdentification.sellerType = response.data.get_seller_customer.sellerType;
              this.purchaseQuoteData.sellerIdentification.phoneNumber = response.data.get_seller_customer.phoneNumber;
              this.purchaseQuoteData.sellerIdentification.firstName = response.data.get_seller_customer.firstName;
              this.purchaseQuoteData.sellerIdentification.lastName = response.data.get_seller_customer.lastName;
              this.purchaseQuoteData.sellerIdentification.email = response.data.get_seller_customer.email;

              this.purchaseQuoteData.sellerIdentification.unitNumber = response.data.get_seller_customer.unitNumber;
              this.purchaseQuoteData.sellerIdentification.provence = response.data.get_seller_customer.provence;
              this.purchaseQuoteData.sellerIdentification.streetAddress = response.data.get_seller_customer.streetAddress;
              this.purchaseQuoteData.sellerIdentification.city = response.data.get_seller_customer.city;
              this.purchaseQuoteData.sellerIdentification.postalCode = response.data.get_seller_customer.postalCode;
              this.purchaseQuoteData.sellerIdentification.country = response.data.get_seller_customer.country;
              this.purchaseQuoteData.sellerIdentification.gst = response.data.get_seller_customer.gst;
              this.purchaseQuoteData.sellerIdentification.pst = response.data.get_seller_customer.pst;
              this.purchaseQuoteData.sellerIdentification.dealer = response.data.get_seller_customer.dealer;
              this.purchaseQuoteData.sellerIdentification.customerId = response.data.get_seller_customer.id;

              this.purchaseQuoteData.sellerIdentification.contactPerson = response.data.get_seller_customer.contactPerson;
              this.purchaseQuoteData.sellerIdentification.contactInfo = response.data.get_seller_customer.contactInfo;

              this.purchaseQuoteData.sellerIdentification.driverLicenseNumber = response.data.get_seller_customer.driverLicenseNumber;
              this.purchaseQuoteData.sellerIdentification.otherIdNumber = response.data.get_seller_customer.otherIdNumber;
              this.purchaseQuoteData.sellerIdentification.dateOfBirth = response.data.get_seller_customer.dateOfBirth;
            }

            if (response.data.coOwnerList.length == 0) {
              //this.addCoOwner();
            }

            this.purchaseQuoteData.coOwnerList.forEach((element, index) => {
              //   this.purchaseQuoteData.coOwner.push(element);

              this.purchaseQuoteData.coOwner[index].sellerType = element.sellerType;
              this.purchaseQuoteData.coOwner[index].phoneNumber = element.phoneNumber;
              this.purchaseQuoteData.coOwner[index].firstName = element.firstName;
              this.purchaseQuoteData.coOwner[index].lastName = element.lastName;
              this.purchaseQuoteData.coOwner[index].email = element.email;

              this.purchaseQuoteData.coOwner[index].unitNumber = element.unitNumber;
              this.purchaseQuoteData.coOwner[index].provence = element.provence;
              this.purchaseQuoteData.coOwner[index].streetAddress = element.streetAddress;
              this.purchaseQuoteData.coOwner[index].city = element.city;
              this.purchaseQuoteData.coOwner[index].postalCode = element.postalCode;
              this.purchaseQuoteData.coOwner[index].country = element.country;
              this.purchaseQuoteData.coOwner[index].gst = element.gst;
              this.purchaseQuoteData.coOwner[index].pst = element.pst;
              this.purchaseQuoteData.coOwner[index].dealer = element.dealer;

              this.purchaseQuoteData.coOwner[index].driverLicenseNumber = element.driverLicenseNumber;
              this.purchaseQuoteData.coOwner[index].otherIdNumber = element.otherIdNumber;
              this.purchaseQuoteData.coOwner[index].dateOfBirth = element.dateOfBirth;
              this.purchaseQuoteData.coOwner[index].contactPerson = element.contactPerson;
              this.purchaseQuoteData.coOwner[index].contactInfo = element.contactInfo;

              this.purchaseQuoteData.coOwner[index].customerId = element.id;

              // element.customerId = element.id;
              //response.data.coOwner.push(element);
            });

            this.files = response.data.get_files;
            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },
  },
};
</script>

<style>
.textlongvalue {
  line-height: 1.84;
}

.title {
  font-size: 12px;
}

.textproduct {
  font-size: 12px;
}

.noborderright {
  border-right: hidden !important;
}

.valueinput {
  font-size: 16px;
}
</style>
